@import "./components/colors";

.jbm-button-3,
.jbm-button-5:focus,
.jbm-button-5.jbm-hover,
.jbm-button-5:hover,
.jbm-button-5:focus,
.link-primary,
.jbm-menu li a:focus,
.jbm-menu li a.jbm-searchform,
.jbm-mobile-menu-wrap .mobile-hasmenu.mob-menu-active:after,
.jbm-mobile-menu-wrap .mobile-hasmenu.mob-menu-active > a,
.color-main,
.jbm-category-box:hover a.jbm-cat-title,
.jbm-job-apply a,
.jbm-job-detail a:focus,
.jbm-blog-title a:hover,
.jbm-blog-title a:focus,
.widget-links li:focus i,
.footer-bottom-bar p i,
.jbm-social-icon-1.jbm-effect-2 li a:focus,
.job-info-download-links:focus span,
.candidate-personal-info a:focus,
.jbm-social-color-2 li a:focus,
.error h1,
.error span,
a.back-to-home,
.process h2,
a.see-plans,
.who-are-we h2,
.our-services h2,
.our-services .fa,
.our-services .media-heading,
blockquote,
.team-member h5,
.team-member ul li a:hover,
.jbm-success p,
.jbm-plan-inner.text-center .fa,
.jbm-plan-inner strong,
.jbm-plan-signup,
.media h5 a:hover,
.jbm-blog-grid h5 a:hover,
.jbm-standard-layout-in h3 a:hover,
.jbm-author-inner h6,
.jbm-cat-inner h6,
.jbm-cat-outer ul li a:hover,
.jbm-field .forgot,
.subscription i.fa.fa-lightbulb-o,
.subscription span,
.jbm-dashboard-links li a:hover,
.jbm-dashboard-links li a:focus,
.jbm-dashboard-links li a.active,
.jbm-emp-right h4,
.jbm-company-info h4,
.drop:hover .fa-upload,
.drop:focus .fa-upload,
.drop:hover h6,
.drop:focus h6,
.about-us span,
.payment-history h4,
.job-history h4,
.active-status span,
.jbm-hd h2,
.jbm-hd h2 span,
.jbm-process i,
.jbm-process-info h3,
.get-started a,
.jbm-description h4,
.sbmt-application,
.jbm-contact-info h4,
.clearall,
.jbm-heading,
.jbm-emp-sidebar ul li a.active,
.profile-specs ul li.active i,
.profile-specs ul li.active a,
.terms a,
.jbm-notification.jbm-normal a,
.widget-links li a:hover,
.jbm-social-icon-1 li a:hover,
.back-top-link:hover,
.back-top-link:focus,
.jbm-loadmore:hover,
.jbm-loadmore:focus,
.jbm-success span,
.jbm-button-3,
.tag-inner-cont a:hover,
.tag-inner-cont a:focus,
.jbm-load-more-jobs a:hover,
.jbm-load-more-jobs a:focus,
.jbm-candidate-profile > i:hover,
.jbm-sitemap-section ul ul:after,
.jbm-author-outer ul li a:hover,
.jbm-author-outer ul li a:focus,
.jbm-thumb-social ul li a:hover,
.jbm-thumb-social ul li a:focus,
.jbm-menu li:hover > a,
.jbm-menu li a:focus,
.jbm-banner-text .jbm-button-2:hover,
.jbm-banner-text .jbm-button-2:focus {
  color: $colorPrimary;
}

.jbm-button-3:hover,
.jbm-button-3:focus,
.jbm-button-3.jbm-hover,
.jbm-button-5,
.jbm-button-6:focus,
.jbm-menu-container ul ul:before,
.jbm-search-sm-btn,
.section-tit-line,
.section-tit-line-2:after,
.jbm-category-box:hover:before,
.main-1st-bg,
.blog-date .blog-cat,
.blog-redmore,
.jbm-reply-btn:focus,
.back-top-button,
a.expand-candi-info,
.best-candi-time,
a.info-open-button,
.jbm-plan-outer:hover,
.plan-hover,
.hover:hover,
.jbm-paginate ul li a:hover,
.jbm-paginate .fa:hover,
.jbm-login-side:after,
.green,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.jbm-hd h2:before,
.dottedd,
.contact-dt ul li i,
.jbm-alphabets ul li a.selected,
.jbm-section-helpbox,
a.expand-candi-info,
.back-top-button,
.jbm-emp-sidebar ul li a.active:before,
.jbm-emp-sidebar ul li a:hover:before,
.jbm-emp-sidebar ul li a:focus:before,
.jbm-alphabets ul li a.selected,
.jbm-alphabets ul li a:hover,
.jbm-alphabets ul li a:focus,
.jbm-reply-btn:hover,
.jbm-sitemap-section ul ul:before,
.jbm-sitemap-section ul ul:after,
.jbm-button-6.jbm-hover,
.jbm-button-6:hover,
.jbm-button-6:focus {
  background-color: $colorPrimary;
}

.select2-container--open .select2-dropdown,
.jbm-button-3,
.jbm-button-5,
.jbm-button-6:focus,
a.back-to-home,
a.see-plans,
blockquote,
.drop:hover,
.drop:focus,
.about-us p,
.jbm-map-inner,
.list-messages li:hover,
.jbm-process i,
.jbm-stp-prcs:before,
.get-started a,
.sbmt-application,
.jbm-alphabets,
.jbm-candidate-profile:before,
.jbm-menu-wrap,
.jbm-sch-inner,
#subscribe-btn,
.jbm-alphabets ul li a.selected,
.jbm-alphabets ul li a:hover,
.jbm-alphabets ul li a:focus,
.jbm-button-6.jbm-hover,
.jbm-button-6:hover,
.jbm-button-6:focus {
  border-color: $colorPrimary;
}

.jbm-category-box:hover .category-icon svg path {
  fill: $colorPrimary;
}

.jbm-button-3,
.jbm-button-5:focus,
.jbm-button-5.jbm-hover,
.jbm-button-5:hover,
.jbm-button-5:focus,
.link-primary,
.jbm-menu li a:focus,
.jbm-menu li a.jbm-searchform,
.jbm-mobile-menu-wrap .mobile-hasmenu.mob-menu-active:after,
.jbm-mobile-menu-wrap .mobile-hasmenu.mob-menu-active > a,
.select2-container--default .select2-results__option--highlighted[aria-selected],
.jbm-section-title h2,
.jbm-section-title-2 h3,
.color-main,
.jbm-category-box:hover a.jbm-cat-title,
.jbm-job-apply a,
.jbm-job-detail a:focus,
.jbm-blog-title a:hover,
.jbm-blog-title a:focus,
.widget-links li:focus i,
.footer-bottom-bar p i,
.jbm-social-icon-1.jbm-effect-2 li a:focus,
.job-info-download-links:focus span,
.candidate-personal-info a:focus,
.jbm-social-color-2 li a:focus,
.error h1,
.error span,
a.back-to-home,
.process h2,
a.see-plans,
.who-are-we h2,
.our-services h2,
.our-services .fa,
.our-services .media-heading,
blockquote,
.team-member h5,
.team-member ul li a:hover,
.jbm-success p,
.jbm-plan-inner.text-center .fa,
.jbm-plan-inner strong,
.jbm-plan-signup,
.media h5 a:hover,
.jbm-blog-grid h5 a:hover,
.jbm-standard-layout-in h3 a:hover,
.jbm-author-inner h6,
.jbm-cat-inner h6,
.jbm-cat-outer ul li a:hover,
.jbm-field .forgot,
.subscription i.fa.fa-lightbulb-o,
.subscription span,
.jbm-dashboard-links li a:hover,
.jbm-dashboard-links li a:focus,
.jbm-dashboard-links li a.active,
.jbm-emp-right h4,
.jbm-company-info h4,
.drop:hover .fa-upload,
.drop:focus .fa-upload,
.drop:hover h6,
.drop:focus h6,
.about-us span,
.payment-history h4,
.job-history h4,
.active-status span,
.jbm-hd h2,
.jbm-hd h2 span,
.jbm-process i,
.jbm-process-info h3,
.get-started a,
.jbm-description h4,
.sbmt-application,
.jbm-contact-info h4,
.clearall,
.jbm-heading,
.jbm-emp-sidebar ul li a.active,
.profile-specs ul li.active i,
.profile-specs ul li.active a,
.terms a,
.jbm-notification.jbm-normal a,
.widget-links li a:hover,
.jbm-social-icon-1 li a:hover,
.back-top-link:hover,
.back-top-link:focus,
.jbm-loadmore:hover,
.jbm-loadmore:focus,
.jbm-topbar a:hover,
.jbm-topbar a:focus,
.jbm-success span,
.jbm-button-3,
.tag-inner-cont a:hover,
.tag-inner-cont a:focus,
.jbm-load-more-jobs a:hover,
.jbm-load-more-jobs a:focus,
.jbm-candidate-profile > i:hover,
.jbm-sitemap-section ul ul:after,
.jbm-author-outer ul li a:hover,
.jbm-author-outer ul li a:focus,
.jbm-thumb-social ul li a:hover,
.jbm-thumb-social ul li a:focus,
.jbm-menu li:hover > a,
.jbm-menu li a:focus,
.jbm-banner-text .jbm-button-2:hover,
.jbm-banner-text .jbm-button-2:focus {
  color: $colorPrimary;
}

.jbm-button-3:hover,
.jbm-button-3:focus,
.jbm-button-3.jbm-hover,
.jbm-button-5,
.jbm-button-6:focus,
.jbm-menu-container ul ul:before,
.jbm-search-sm-btn,
.section-tit-line,
.section-tit-line-2:after,
.jbm-category-box:hover:before,
.main-1st-bg,
.blog-date .blog-cat,
.blog-redmore,
.jbm-reply-btn:focus,
.back-top-button,
a.expand-candi-info,
.best-candi-time,
a.info-open-button,
.jbm-plan-outer:hover,
.plan-hover,
.hover:hover,
.jbm-paginate ul li a:hover,
.jbm-paginate .fa:hover,
.jbm-login-side:after,
.green,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.jbm-hd h2:before,
.dottedd,
.contact-dt ul li i,
.jbm-alphabets ul li a.selected,
.jbm-section-helpbox,
a.expand-candi-info,
.back-top-button,
.jbm-emp-sidebar ul li a.active:before,
.jbm-emp-sidebar ul li a:hover:before,
.jbm-emp-sidebar ul li a:focus:before,
.jbm-alphabets ul li a.selected,
.jbm-alphabets ul li a:hover,
.jbm-alphabets ul li a:focus,
.jbm-reply-btn:hover,
.jbm-sitemap-section ul ul:before,
.jbm-sitemap-section ul ul:after,
.jbm-button-6.jbm-hover,
.jbm-button-6:hover,
.jbm-button-6:focus {
  background-color: $colorPrimary;
}

.jbm-button-3,
.jbm-button-5,
.jbm-button-6:focus,
a.back-to-home,
a.see-plans,
blockquote,
.drop:hover,
.drop:focus,
.about-us p,
.jbm-map-inner,
.list-messages li:hover,
.jbm-process i,
.jbm-stp-prcs:before,
.get-started a,
.sbmt-application,
.jbm-alphabets,
.jbm-candidate-profile:before,
.jbm-menu-wrap,
.jbm-sch-inner,
#subscribe-btn,
.jbm-alphabets ul li a.selected,
.jbm-alphabets ul li a:hover,
.jbm-alphabets ul li a:focus,
.jbm-button-6.jbm-hover,
.jbm-button-6:hover,
.jbm-button-6:focus {
  border-color: $colorPrimary;
}

.jbm-category-box:hover .category-icon svg path {
  fill: $colorPrimary;
}